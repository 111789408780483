var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { ref: "app" },
    [_c("v-main", { attrs: { app: "" } }, [_vm._t("default")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }