var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      ref: "app",
      class: {
        "bg-light": !_vm.$vuetify.theme.dark,
        "bg-dark": _vm.$vuetify.theme.dark
      }
    },
    [
      _c("v-main", { attrs: { app: "" } }, [
        _c(
          "div",
          { staticClass: "centered" },
          [
            _c("h1", { staticClass: "text-h3 pb-2" }, [
              _vm._v("Whoops, " + _vm._s(_vm.status))
            ]),
            _c("br"),
            _vm.message && _vm.message.length > 0
              ? _c(
                  "p",
                  {
                    staticClass: "font-italic",
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v(" " + _vm._s(_vm.message) + " "), _c("br")]
                )
              : _vm._e(),
            _vm.details && _vm.details.length > 0
              ? _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(" " + _vm._s(_vm.details) + " "),
                  _c("br")
                ])
              : _vm._e(),
            _c("br"),
            _c(
              "v-btn",
              { attrs: { to: "/", color: "background" } },
              [
                _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-home")]),
                _vm._v(" Navigate to homepage ")
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }