var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      ref: "app",
      class: {
        "bg-light": !_vm.$vuetify.theme.dark,
        "bg-dark": _vm.$vuetify.theme.dark
      }
    },
    [
      _vm.$store.getters["menu/drawerMenu"].length > 0
        ? _c("asyent-drawer", {
            attrs: {
              "drawer-menu": _vm.$store.getters["menu/drawerMenu"],
              app: ""
            }
          })
        : _vm._e(),
      _vm.$store.state["toolbar"]["visible"]
        ? _c("asyent-toolbar", { attrs: { standalone: false } })
        : _vm._e(),
      _c("v-main", { attrs: { app: "" } }, [_vm._t("default")], 2),
      _c("asyent-footer"),
      _vm.appConfig.navigation.floatingNavigation &&
      _vm.$store.getters["menu/drawerMenu"].length > 0
        ? _c(
            "v-menu",
            {
              attrs: {
                "aria-label": "Application Menu",
                bottom: "",
                "offset-y": "",
                "offset-x": "",
                "open-on-click": "",
                "offset-overflow": "",
                "close-on-content-click": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              class: { "fab-btn": true },
                              attrs: {
                                "aria-label": "Open Application Menu",
                                bottom: "",
                                color: "primary",
                                fab: "",
                                fixed: "",
                                large: "",
                                right: ""
                              }
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-menu")])],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1410527072
              )
            },
            [
              _c("asyent-drawer-menu", {
                attrs: {
                  nav: "",
                  dense: "",
                  "drawer-menu": _vm.$store.getters["menu/drawerMenu"]
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }